<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <bullet
        :title="'Detail Community Activities'"
        :canBack="true"
        colorIcon="#052633"
      />
      <div class="mt-4 d-flex align-center justify-center full_w">
        <div class="_temp_list_medium">
          <div>
            <v-card flat color="#052633" rounded="lg">
              <v-row no-gutters>
                <v-col cols="12" md="8" style="display: grid">
                  <div
                    class="full_w pa-5 d-flex align-start justify-center flex-column"
                  >
                    <h2 v-if="!loading && dataSet" class="txt_white">
                      {{ dataSet.nama }}
                    </h2>
                    <v-skeleton-loader
                      v-if="loading"
                      class="rounded-lg"
                      width="90px"
                      height="20px"
                      type="image"
                    ></v-skeleton-loader>
                    <h4 v-if="!loading && dataSet" class="not_bold txt_white">
                      {{ dataSet.tujuan }}
                    </h4>
                    <v-skeleton-loader
                      v-if="loading"
                      class="rounded-lg mt-1"
                      width="100%"
                      height="40px"
                      type="image"
                    ></v-skeleton-loader>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="px-4 py-2 d-flex align-center justify-end"
                >
                  <img
                    src="../../assets/img/activities.png"
                    height="100px"
                    style="object-fit: contain"
                  />
                </v-col>
              </v-row>
            </v-card>
          </div>
          <div class="mt-3">
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <div class="pa-3">
                  <h4>Waktu Event</h4>
                  <div class="mt-3 d-flex align-center">
                    <v-icon class="mr-2">mdi-calendar</v-icon>

                    <h4 v-if="!loading && dataSet">
                      {{
                        $date(dataSet.tanggal_pelaksanaan).format(
                          "DD MMMM YYYY"
                        )
                      }}
                    </h4>
                    <v-skeleton-loader
                      v-if="loading"
                      class="rounded-lg mt-1"
                      width="100%"
                      height="40px"
                      type="image"
                    ></v-skeleton-loader>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="pa-3">
                  <h4>Lokasi dan Link</h4>
                  <div class="mt-3 d-flex align-center text-capitalize">
                    <v-icon class="mr-2">mdi-web</v-icon>
                    <h4 v-if="!loading && dataSet">
                      {{ dataSet.metode }}
                    </h4>
                    <v-skeleton-loader
                      v-if="loading"
                      class="rounded-lg mt-1"
                      width="100%"
                      height="40px"
                      type="image"
                    ></v-skeleton-loader>
                  </div>
                  <div class="mt-3 d-flex align-center text-capitalize">
                    <v-icon class="mr-2">mdi-map-marker</v-icon>
                    <h4 v-if="!loading && dataSet">
                      {{ dataSet.lokasi }}
                    </h4>
                    <v-skeleton-loader
                      v-if="loading"
                      class="rounded-lg mt-1"
                      width="100%"
                      height="40px"
                      type="image"
                    ></v-skeleton-loader>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="mt-3 pa-3">
            <h4>Instansi Partner yang mengikuti kegiatan</h4>
            <v-data-table
              v-if="dataPartner && !loading"
              :headers="headers"
              :items="dataSet.instansi_partner"
              item-key="nama"
              class="elevation-0 mt-3"
              hide-default-footer
              :single-select="false"
            >
            </v-data-table>
            <v-skeleton-loader
              class="pa-2 elevation-0"
              type="table-tbody"
              v-if="loading"
            ></v-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { NavbarProfile, Bullet },
  name: "updateCommunityActivites",
  data() {
    return {
      loading: true,
      dataSet: null,
      dataPartner: null,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Community Activities",
          disabled: false,
          href: "/admin/cd/community-activities",
        },
        {
          text: "Detail Community Activities",
          disabled: true,
          href: "#",
        },
      ],
      headers: [
        { text: "Instansi Partner", value: "instansi.nama_instansi" },
        { text: "Email", value: "instansi.email" },
        { text: "Alamat", value: "instansi.alamat" },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/admin/community-development/event/list?filter[id]=${this.$route.params.idActivities}&include=instansi_partner`,
      };
      this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          console.log(data);
          this.dataSet = data.data.data[0];
          this.fetchDataPartner();
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    fetchDataPartner() {
      this.loading = true;
      let data = {
        path: `sysadmin/instansi-partner`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataPartner = data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
